import { ScopeContext } from "@sentry/core"
import * as Sentry from "@sentry/react"
import axios, { AxiosError } from "axios"
import { isNil, isNumber } from "lodash-es"

import isErrorWithResponse from "./isErrorWithResponse"
import isNotErrorButHasMessage from "./isNotErrorButHasMessage"

/**
 * If the response is missing a status or is not present in the ignores it is valid
 * Not being present should be rare but it would be captured in the tags by reportAxiosError.
 * @param status status code from HTTP/Axios response to validate against
 * @param ignores HTTP codes to consider invalid
 * @param ignores the errorReason return in the response.data
 * @returns if the status is one we care about
 */
export const isValidStatus = (
  status?: number,
  ignores?: (number | { code: number; reasons: string[] })[],
  reason?: string
) =>
  isNil(status) ||
  !ignores?.find((ignore) =>
    isNumber(ignore)
      ? status === ignore
      : status === ignore.code && reason && ignore.reasons.includes(reason)
  )

type ReportConfig = {
  prettyURL?: string
  ignoreStatuses?: (number | { code: number; reasons: string[] })[]
  buildTagsFromError?: (error: AxiosError) => Record<string, string> | null
  errorReasonMessage?: boolean
}

type ReportConfigFields = keyof ReportConfig

const disallowedFields: ReportConfigFields[] = [
  "prettyURL",
  "ignoreStatuses",
  "buildTagsFromError",
  "errorReasonMessage",
]

export type ReportContext = Partial<ScopeContext> & ReportConfig

export const stripConfigFromContext = (
  context?: ReportContext
): Partial<ScopeContext> | undefined => {
  if (!context) {
    return undefined
  }
  const keys = Object.keys(context)
  const scopeContext: Partial<ScopeContext> = keys.some((key) =>
    disallowedFields.includes(key as keyof ReportConfig)
  )
    ? keys
        .filter((key) => !(disallowedFields as string[]).includes(key))
        .reduce<Partial<ScopeContext>>((obj, key) => {
          return {
            ...obj,
            [key]: context[key as keyof ScopeContext],
          }
        }, {})
    : (context as Partial<ScopeContext>)
  return scopeContext
}

// Use these exports in prettyURLs to replace actual IDs
export const PRETTY_CONSUMER_ID = "{consumer.id}"
export const PRETTY_FIRM_ID = "{firm.id}"
export const PRETTY_MORTGAGE_ID = "{mortgage.id}"
export const PRETTY_APPROVAL_ID = "{approval.id}"
export const PRETTY_EMPLOYEE_ID = "{employee.id}"
export const PRETTY_ACTIVITY_ID = "{activity.id}"
export const PRETTY_PROPERTY_ID = "{property.id}"

/**
 * Handles capturing errors in Sentry thrown from an Axios call (GET, POST etc).
 * If a non AxiosError is caught this is also captured by Sentry.
 * Network Errors are ignored by default.
 * Routes with URL params e.g /consumer/:id should have a pretty URL defined to help fingerprint the error in Sentry
 *
 * @param {unknown} error  The error caught by catch associated with an Axios call
 * @param {ReportContext} config Configuration options for the Sentry capture, with additional options added below
 *
 * @param prettyURL The URL with ids and other params stripped out and replaced with readable/groupable values
 * @param ignoreStatuses Status codes e.g. 401 that are expected from the Axios throw and should be captured
 * @param statusHandler Builds tags using the AxiosError
 * @param captureContext Additional scope data to apply to exception event. Do not add PII. The response.status code will be added automatically.
 * @param errorReasonMessage Should the SerializedError use the error message or the response errorReason field
 */
export default function reportAxiosError(error: unknown, config?: ReportContext) {
  if (axios.isCancel(error)) {
    // Do nothing if the axios request was cancelled - we considered making this configurable but have not for now, can update in future if needed
    return
  }
  const scopeContext = stripConfigFromContext(config)
  if (!axios.isAxiosError(error)) {
    // If reportAxiosError was called, but it turns out not be an AxiosError - we should report this to Sentry
    if (isNotErrorButHasMessage(error)) {
      Sentry.captureMessage(error.message, scopeContext)
    }
    Sentry.captureException(error, scopeContext)
    return
  }
  if (
    !isErrorWithResponse(error) ||
    !isValidStatus(
      error.response?.status,
      config?.ignoreStatuses,
      error.response?.data?.errorReason
    )
  ) {
    // Do nothing if this was an error without a response e.g. a Network Error or an invalid status
    return
  }
  const path = config?.prettyURL || error.request?.url || error.config?.url
  const errorReason: string | undefined = error.response?.data?.errorReason
  Sentry.captureMessage(
    `${error.config?.method?.toLocaleUpperCase()} ${
      path ?? "URL missing from request and config"
    } - ${error.message}`,
    {
      level: "error",
      ...scopeContext,
      tags: {
        ...config?.tags,
        "response.status": error.response?.status,
        ...config?.buildTagsFromError?.(error),
        ...(errorReason ? { errorReason } : {}),
      },
    }
  )
}
