import { notInterestedCategories } from "../retain"
import { BareUser, consumerState } from "./Consumer.types"
import { mortgageState, mortgageTypes } from "./Mortgages.types"

export enum retainEventTypes {
  ACCOUNT_CREATED = "account-created",
  APPLY = "apply",
  ASSIGNED = "assigned",
  BAD_DATA = "bad-data",
  BAD_DATA_RESOLVED = "bad-data-resolved",
  BLOG_POST_VIEWED = "blog-post-viewed",
  BOOKED_APPOINTMENT = "booked-appointment",
  CALL_REQUESTED = "call-requested",
  CHOOSE_SVR = "choose-svr",
  COMMS_SNOOZED = "comms-snoozed",
  DASHBOARD_VIEWED = "dashboard-viewed",
  EMAIL_CLICKED = "email-clicked",
  EMAIL_OPEN = "email-open",
  EMAIL_SENT = "email-sent",
  EXTERNAL_ADVICE = "external-advice",
  GET_HELP = "get-help",
  GET_HELP_MENU = "get-help-menu",
  INTERNAL_ADVICE = "internal-advice",
  LEAVE_SVR = "leave-svr",
  LIFECYCLE_CALL_REQUESTED = "lifecycle-call-requested",
  LIST_HIDE = "list-hide",
  LOGGED_IN = "logged-in",
  LOST = "lost",
  MEETING_BOOKED = "meeting-booked",
  NO_ANSWER = "no-answer",
  NOT_INTERESTED = "not-interested",
  OFFER = "offer",
  OFFER_SENT = "offer-sent",
  OPTIONS_VIEWED = "options-viewed",
  PAYMENTS_VIEWED = "payments-viewed",
  PORTAL_CONTACT = "portal-contact",
  PORTALLED = "portalled",
  PRODUCT_SELECTED = "product-selected", // Deprecated
  PRODUCTS_CONFIRMED = "products-confirmed",
  PRODUCTS_VIEWED = "products-viewed",
  PROPERTY_VIEWED = "property-viewed",
  PROTECTION_REFERRED = "protection-referred", // Deprecated
  REDEEMED = "redeemed",
  REDIRECTED = "redirected",
  REMIND_WHEN_PRODUCTS_AVAILABLE = "remind-when-products-available",
  RESET = "consumer-reset",
  RESUME = "resume",
  RETAINED = "retained",
  SET_POINT_OF_CONTACT = "set-point-of-contact",
  SNOOZE_REQUESTED = "snooze-requested",
  STATE_CHANGE_CONSUMER = "state-change--consumer", // Deprecated
  STATE_CHANGE_MORTGAGE = "state-change--mortgage", // Deprecated
  STOP_ALERTS = "stop-alerts",
  TOPIC_READ = "topic-read",
  UNDERSTANDING_COMPLETE = "understanding-complete",
  VOICEMAIL_LEFT = "voicemail-left",
  WHY_IS_THIS_HAPPENING_VIEWED = "why-is-this-happening-viewed",
  WITH_SWITCH_SUPPORT = "with-switch-support",
  WITHOUT_ADVICE = "without-advice",
}

export interface BaseEvent {
  id: string
  name: string
  author: BareUser
  mortgageType: mortgageTypes | null
  count: number | null
  target: BareUser
  parentEvent: Event | null
  propertyAddress: string | null
  previousEvents: Event[] | undefined
  createdAt: Date
  updatedAt: Date
}

export type EmailSentExtraData = {
  fromName: string
  fromEmail: string
  fromFull: string
  toName: string
  toEmail: string
  toFull: string
  subject: string
  sentAt: Date
  body: string
  propId: string
  mortgageId: string
}
export type StateChangeEvent = BaseEvent & {
  type: retainEventTypes.STATE_CHANGE_MORTGAGE | retainEventTypes.STATE_CHANGE_CONSUMER
  extraData: {
    previousState: consumerState | mortgageState | null
    newState: consumerState | mortgageState | null
    notInterestedCategory: notInterestedCategories
    reasonForNotInterestedOther: string
    description: string
    meetingBookedFrom?: Date
    meetingBookedUntil?: Date
  }
}
export type SnoozeEvent = BaseEvent & {
  type:
    | retainEventTypes.SNOOZE_REQUESTED
    | retainEventTypes.NO_ANSWER
    | retainEventTypes.VOICEMAIL_LEFT
  extraData: { snoozedUntil: Date | null }
}
export type EmailSentEvent = BaseEvent & {
  type: retainEventTypes.EMAIL_SENT
  extraData: EmailSentExtraData
}
export type EmailOpenEvent = BaseEvent & {
  type: retainEventTypes.EMAIL_OPEN
  parentEvent: EmailSentEvent
  extraData: {
    ip: string
    userAgent: string
  }
}
export type EmailClickEvent = BaseEvent & {
  type: retainEventTypes.EMAIL_CLICKED
  parentEvent: EmailSentEvent
  extraData: {
    ip: string
    userAgent: string
    url: string
  }
}
export type BadDataEvent = BaseEvent & {
  type: retainEventTypes.BAD_DATA
  extraData: {
    description: string
    removeFromComms: boolean
  }
}
export type BadDataResolvedEvent = BaseEvent & {
  type: retainEventTypes.BAD_DATA_RESOLVED
  extraData: {
    description: string
  }
}
export type MeetingBookedEvent = BaseEvent & {
  type: retainEventTypes.MEETING_BOOKED
  extraData: {
    meetingBookedFrom: Date
    meetingBookedUntil: Date
    previousState: consumerState | mortgageState | null
    newState: consumerState | mortgageState | null
  }
}
export type SetPointOfContactEvent = BaseEvent & {
  type: retainEventTypes.SET_POINT_OF_CONTACT
  extraData: {
    oldviewAsConsumerId: string
    oldPointOfContactName: string
    emailEveryone: boolean
  }
}
export type MinimalEvent = BaseEvent & {
  type:
    | retainEventTypes.ACCOUNT_CREATED
    | retainEventTypes.LOGGED_IN
    | retainEventTypes.CALL_REQUESTED
    | retainEventTypes.ASSIGNED
    | retainEventTypes.RESET
    | retainEventTypes.LIST_HIDE
    | retainEventTypes.RETAINED
    | retainEventTypes.PORTALLED
    | retainEventTypes.BOOKED_APPOINTMENT
    | retainEventTypes.SET_POINT_OF_CONTACT
    | retainEventTypes.NOT_INTERESTED
    | retainEventTypes.LOST
    | retainEventTypes.PROTECTION_REFERRED // Deprecated
  extraData: null
}
export type Event =
  | StateChangeEvent
  | SnoozeEvent
  | EmailSentEvent
  | EmailOpenEvent
  | EmailClickEvent
  | BadDataEvent
  | BadDataResolvedEvent
  | MinimalEvent
  | MeetingBookedEvent
  | SetPointOfContactEvent

export interface ExtraData {
  newState: string
  previousState: null
  meetingBookedFrom: string
  meetingBookedUntil: string
  sentAt: string
  snoozedUntil?: string
}

export interface AuthorType {
  id: string
  name: string
  isUser: boolean
  isConsumer: boolean
  isEmployee: boolean
}

export interface FakeEventTypes {
  id: string
  type: string
  name: string | null
  author: AuthorType
  target: AuthorType
  parentEvent: {
    newState: string
    extraData: ExtraData
  }
  mortgageType: null
  extraData: ExtraData
  createdAt: string
  updatedAt: string
}
