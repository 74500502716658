import { createApi } from "@reduxjs/toolkit/query/react"

import axiosBaseQuery from "./axiosBaseQuery"
import { consumerTagsArray } from "./consumerTags"

export const consumerApi = createApi({
  tagTypes: consumerTagsArray,
  reducerPath: "consumerApi",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api-v1",
  }),
  endpoints: () => ({}),
})
