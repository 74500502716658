import { Dispatch } from "redux"

import { businessProductSelectionApi } from "./business/productSelection"
import { consumerApi } from "./consumerApi"
import { consumerFactorApi } from "./consumerFactor/consumerFactorApi"
import { employeeFactorApi } from "./employeeFactor/employeeFactorApi"
import { lifecycleProspectsApi } from "./lifecycleProspects/lifecycleProspectsApi"
import { onboardingFactorApi } from "./onboardingFactor/onboardingFactorApi"

function resetApiStateOnAllServices(dispatch: Dispatch) {
  dispatch(consumerApi.util?.resetApiState())
  dispatch(businessProductSelectionApi.util?.resetApiState())
  dispatch(consumerFactorApi.util?.resetApiState())
  dispatch(employeeFactorApi.util?.resetApiState())
  dispatch(lifecycleProspectsApi.util?.resetApiState())
  dispatch(onboardingFactorApi.util?.resetApiState())
}

export default resetApiStateOnAllServices
