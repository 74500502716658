import { lifecycleModule } from "../../components/consumer/Lifecycle/utils/lifecycleModules"

export const consumerTags = {
  whatsNext: "whatsNext",
  page: "page",
  topics: "topics",
  interestRates: "interestRates",
  productRates: "productRates",
  expiryDate: "expiryDate",
  propertyValue: "propertyValue",
  loanToValue: "loanToValue",
  mortgages: "mortgages",
} as const

export const consumerTagMap: Record<lifecycleModule, keyof typeof consumerTags> = {
  [lifecycleModule.INTEREST_RATES]: consumerTags.interestRates,
  [lifecycleModule.PRODUCT_RATES]: consumerTags.productRates,
  [lifecycleModule.PROPERTY_VALUE]: consumerTags.propertyValue,
  [lifecycleModule.EXPIRY_DATE]: consumerTags.expiryDate,
  [lifecycleModule.LOAN_TO_VALUE]: consumerTags.loanToValue,
}

export const consumerTagsArray = Object.values(consumerTags)
