import { useEffect, useRef, useState } from "react"

import Container from "../../common/Container"
import LoadingIndicator from "../../common/LoadingIndicator"

export default function LoadingPage() {
  const timerId = useRef<number>()
  const [render, setRender] = useState(false)
  useEffect(() => {
    timerId.current = window.setTimeout(() => {
      setRender(true)
    }, 200)
    return () => {
      window.clearTimeout(timerId.current)
    }
  }, [])
  return render ? (
    <Container isFullHeight bg="transparent">
      <div
        style={{
          display: "grid",
          placeContent: "center",
        }}
      >
        <LoadingIndicator />
      </div>
    </Container>
  ) : null
}
