import "normalize.css"
import "react-app-polyfill/stable"
import "array-flat-polyfill"
import "abortcontroller-polyfill/dist/abortcontroller-polyfill-only"

import * as Sentry from "@sentry/react"
import axios from "axios"
import { createRoot } from "react-dom/client"
import { HelmetProvider } from "react-helmet-async"
import { Provider as StoreProvider } from "react-redux"
import { BrowserRouter } from "react-router-dom"

import App from "./components/App"
import ErrorBoundary from "./components/common/ErrorBoundary"
import { filterNavigateToPII } from "./lib/filterNavigateToPII"
import isEventWithSourceFile from "./lib/isEventWithSourceFile"
import isRtkQueryError from "./lib/isRtkQueryError"
import { storageAvailable } from "./lib/localStorageUtil"
import retry from "./lib/retry"
import { initEnv, runtimeEnv } from "./lib/runtimeEnv"
import store from "./redux"

const ORIGIN_FINGERPRINT = "missing-origin-header"
const CHUNK_FINGERPRINT = "chunk-load-error"
const GOOGLE_TRANSLATE_FINGERPRINT = "google-translate-undefined"

function initialise() {
  const env = runtimeEnv
  axios.defaults.baseURL = env.ENV_BACKEND_API_END_POINT

  if (env.VITE_SENTRY_DSN) {
    Sentry.init({
      dsn: env.VITE_SENTRY_DSN,
      environment: env.ENV_ENVIRONMENT || "unknown",
      ignoreErrors: [
        // This is a rejection thrown by Outlook https://github.com/getsentry/sentry-javascript/issues/3440
        "Non-Error promise rejection captured with value: Object Not Found Matching Id:",
        // Error thrown by iOS Edge https://github.com/getsentry/sentry-javascript/issues/8444
        "Can't find variable: msDiscoverChatAvailable",
        /**
         * React internal error thrown when something outside react modifies the DOM
         * This is usually because of a browser extension or Chrome's built-in translate
         */
        "NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
        "NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",
      ],
      denyUrls: [/^webkit-masked-url:\/\/hidden/i],
      beforeBreadcrumb(breadcrumb) {
        if (breadcrumb.category === "ui.click") {
          breadcrumb.message = filterNavigateToPII(breadcrumb.message)
        }
        return breadcrumb
      },
      beforeSend(event, hint) {
        if (isEventWithSourceFile(event)) {
          if (event.extra?.body.sourceFile.startsWith("chrome-extension")) {
            return null
          }
        }

        // These events are believed to come from email scanning tools
        if (
          event.tags?.["response.status"] === 403 &&
          event.tags?.["403.reason"] === "Missing 'Origin' header"
        ) {
          if (env.ENV_SENTRY_REPORTS?.includes(ORIGIN_FINGERPRINT)) {
            event.fingerprint = [ORIGIN_FINGERPRINT]
            return event
          }
          return null
        }

        const exception = hint.originalException

        // Ignore SerializedErrors caught by routing error boundaries from expected network errors
        if (
          isRtkQueryError(exception) &&
          exception.message &&
          /^timeout exceeded|Network error$/.test(exception.message)
        ) {
          return null
        }

        // Simple chunk load errors from network failure
        if (
          exception &&
          exception instanceof Error &&
          (exception.message.startsWith(
            "Failed to fetch dynamically imported module:"
          ) ||
            exception.message.startsWith("error loading dynamically imported module:"))
        ) {
          if (env.ENV_SENTRY_REPORTS?.includes(CHUNK_FINGERPRINT)) {
            event.fingerprint = [CHUNK_FINGERPRINT]
            return event
          }
          return null
        }

        // Bad interactions between Google Translate and React
        if (
          exception &&
          exception instanceof Error &&
          (exception.message.match(/undefined is not an object \(evaluating/i) ||
            exception.message.match(/The object can not be found here/i)) &&
          event.breadcrumbs?.find(
            (crumb) =>
              crumb.category === "xhr" &&
              crumb.data?.url?.match(/https:\/\/translate(-pa)?.googleapis.com\//)
          )
        ) {
          if (env.ENV_SENTRY_REPORTS?.includes(GOOGLE_TRANSLATE_FINGERPRINT)) {
            event.fingerprint = [GOOGLE_TRANSLATE_FINGERPRINT]
            return event
          }
          return null
        }

        return event
      },
    })

    Sentry.getCurrentScope().setTag(
      "localStorage.available",
      String(storageAvailable("localStorage"))
    )
    Sentry.getCurrentScope().setTag(
      "sessionStorage.available",
      String(storageAvailable("sessionStorage"))
    )
  }

  const container = document.getElementById("root")

  if (container && env.ENV_DOWNTIME_MSG) {
    const root = createRoot(container)
    root.render(
      <main className="downtime">
        <section>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1}
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z"
            />
          </svg>
          <p>This site is currently down for planned maintenance.</p>
          <p>{env.ENV_DOWNTIME_MSG}</p>
        </section>
      </main>
    )
  } else if (container) {
    const root = createRoot(container)
    root.render(
      <BrowserRouter>
        <HelmetProvider>
          <StoreProvider store={store}>
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </StoreProvider>
        </HelmetProvider>
      </BrowserRouter>
    )
  } else {
    console.error("Unable to start. Perhaps the app was embedded on the wrong page")
  }
}

retry(initEnv, 8)
  .then(() => initialise())
  .catch((err: unknown) => {
    console.error(err)
    Sentry.init({
      dsn: runtimeEnv.VITE_SENTRY_DSN,
      environment: runtimeEnv.ENV_ENVIRONMENT,
    })
    Sentry.captureException(err)
  })
