import { retry } from "@reduxjs/toolkit/dist/query/react"
import axios from "axios"

import { buildSerializedError } from "../../../lib/buildSerializedError"
import reportAxiosError from "../../../lib/reportAxiosError"
import { Content } from "../../../types/retain/Content.types"
import buildTagsFrom403 from "../../actions/buildTagsFrom403"
import { consumerApi } from "../consumerApi"

export const contentApi = consumerApi.injectEndpoints({
  endpoints: (builder) => ({
    getContent: builder.query<Content, void>({
      queryFn: retry(async () => {
        try {
          const { data } = await axios.get<Content>("/api-v1/content/")
          return { data }
        } catch (err) {
          reportAxiosError(err, {
            buildTagsFromError: buildTagsFrom403,
          })
          const error = buildSerializedError(err)
          return { error }
        }
      }),
      keepUnusedDataFor: 60 * 60 * 24, // 1 day in seconds
    }),
  }),
})

export const { useGetContentQuery } = contentApi
